import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import imgCruiseCover from "../../assets/images/journey/cruise/cruise-cover.png"

const CruisePage = () => (
  <Layout lang="ar">
    <SEO title="Cruise Phase" lang="ar" />
    <main>
      <div className="uk-cover-container" data-uk-height-viewport="offset-top: true; offset-bottom: 40" style={{borderBottom: "5px solid #D96C32"}}>
        <img src={imgCruiseCover} alt="" style={{transform: "translate(50%,-50%)"}} data-uk-cover />
        <div className="uk-overlay uk-position-cover header-overlay"></div>
      </div>
      <section id="about-emm" style={{marginTop: "-100px", position: "relative"}}>
          <div className="large-container pb6">
            <div className="standard-container standard-bg">
                <div>
                  <h2 className="relative">
                  مرحلة الملاحة                    <div className="text-underline-heavy"></div>
                  </h2>
                  <p className="mt5">
                  يقوم فريق العمليات في المحطة الأرضية بمتابعة "مسبار الأمل" باستمرار وهو يشق طريقه نحو المريخ. وعلى مدار الأشهر القادمة يعمل الفريق على إجراء سلسلة مناورات لتحسين مسار المسبار إلى المريخ تُعرف باسم (مناورات تصحيح المسار).
                  </p>
                  <p>
                  وخلال هذه المرحلة يتم تشغيل الأجهزة العلمية وفحصها للتأكد من أنها تعمل بشكل صحيح، ويتم فحص الأجهزة بتوجيهها نحو النجوم للتأكد من سلامة زوايا المحاذاة الخاصة بها والتأكد من أنها جاهزة للعمل بمجرد وصولها إلى المريخ.
                  </p>
                  <p>
                  في نهاية هذه المرحلة يقترب "مسبار الأمل" من المريخ بسرعة محددة وزاوية انحراف دقيقة حتى يتمكن من الدخول إلى مدار المريخ.
                  </p>
                </div>
            </div>    
                 
          </div>  
      </section>
    </main>
  </Layout>
)

export default CruisePage
